import React from 'react';

// === Styles === //
import styled from '@emotion/styled';

// === Components === //
import BookmarkListItem from '@components/sections/components/BookmarkListItem';

// === Types === //
import { HomepageBookmarksData } from '@content/types/homepage';
import { Variant } from '@content/types/layout';

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  padding: 0;
  justify-content: center;
`;

interface BookmarkListProps {
  data: HomepageBookmarksData[];
  variant: Variant;
}

const BookmarkList = ({ data, variant }: BookmarkListProps) => {
  return (
    <List>
      {data.map((item) => (
        <BookmarkListItem
          key={item.id}
          icon={item.icon}
          text={item.title}
          btn_text={item.button_text}
          buttonLink={item.buttonLink}
          variant={variant}
        />
      ))}
    </List>
  );
};

export default BookmarkList;
