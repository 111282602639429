import React from 'react';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, respondTo } from '@styles';

// === Components === //
import ButtonLink from '@components/common/ButtonLink';

// === Types === //
import { Variant } from '@content/types/layout';

const ListWrapper = styled.li`
  margin: 0 7px 60px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.533);
  width: 100%;
  max-width: 240px;
  border-radius: 22px;
  align-items: center;
  position: relative;
  background-color: ${colors.white};
  ${respondTo(
    breakpoints.md,
    css`
      max-width: 360px;
    `
  )}

  &::after {
    content: '';
    background: ${colors.white};
    z-index: 1;
    top: 45%;
    bottom: 0px;
    right: -14px;
    position: absolute;
    width: 24px;
    height: 60px;
    filter: blur(4px);
  }

  &::before {
    content: '';
    background: ${colors.white};
    z-index: 1;
    top: 45%;
    bottom: 0px;
    left: -14px;
    position: absolute;
    width: 24px;
    height: 60px;
    filter: blur(4px);
  }
`;

const ListIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 89px;
  height: 89px;
  background: ${colors.white};
  position: absolute;
  top: -37px;
  box-shadow: 0px -15px 6px rgba(0, 0, 0, 0.161);
`;

const ListIcon = styled.img`
  width: 61px;
  height: 61px;
`;

const ListText = styled.p`
  margin: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 60px 0 20px;
`;

interface DefalutComponentProps {
  icon: string;
  text: string;
  btn_text: string;
  buttonLink: string;
  variant: Variant;
}

const BookmarkListItem = ({ icon, text, btn_text, buttonLink, variant }: DefalutComponentProps) => {
  return (
    <ListWrapper>
      <ListIconWrapper>
        <ListIcon src={icon} />
      </ListIconWrapper>
      <ListText>{text}</ListText>
      <ButtonLink link={buttonLink} variant={variant}>
        {btn_text}
      </ButtonLink>
    </ListWrapper>
  );
};

export default BookmarkListItem;
