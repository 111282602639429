import React from 'react';

// === Components === //
import { Link } from 'gatsby';

// === Content === //
import iconChevron from '@assets/svg/chevron_right.svg';
import iconExternalLink from '@assets/svg/external_link_icon.svg';
import iconDownload from '@assets/svg/download_icon.svg';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, mixins } from '@styles';

// === Types === //
import { Variant } from '@content/types/layout';

const icons = {
  chevron: iconChevron,
  external_link: iconExternalLink,
  download: iconDownload,
};

const ListButton = styled(Link)<ListButtonProps>`
  display: inline-block;
  margin-bottom: 19px;
  margin-top: auto;
  padding: 9px 30px;
  background: ${(props) =>
    props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
  color: ${colors.white};
  text-transform: uppercase;
  border-radius: 99px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  ${mixins.transitionDefault}
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 600;

  &:hover {
    background: ${(props) =>
      props.variant === 'blue' ? colors.ui.patientDark : colors.ui.hcpDark};
  }
`;

const ListButtonExternal = styled.a<ListButtonProps>`
  display: inline-block;
  margin-bottom: 19px;
  margin-top: auto;
  padding: ${(props) => (props.icon && props.icon !== '' ? '9px 40px 9px 18px' : '9px 30px')};
  background: ${(props) =>
    props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
  color: ${colors.white};
  text-transform: uppercase;
  border-radius: 99px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  ${mixins.transitionDefault}
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 600;
  position: relative;

  &:hover {
    background: ${(props) =>
      props.variant === 'blue' ? colors.ui.patientDark : colors.ui.hcpDark};
  }

  &::after {
    content: '';
    background-image: ${(props) => (props.icon ? `url(${icons[props.icon]})` : 'none')};
    width: 12px;
    height: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 18px;
  }
`;

interface ButtonLinkProps {
  children: React.ReactChild;
  link: string;
  variant: Variant;
  type?: 'external' | 'internal';
  icon?: string;
}

interface ListButtonProps {
  variant: Variant;
  icon?: string;
}

const ButtonLink = ({ children, link, variant, type = 'internal', icon }: ButtonLinkProps) => {
  return (
    <>
      {type === 'external' ? (
        <ListButtonExternal href={link} variant={variant} target="_blank" icon={icon}>
          {children}
        </ListButtonExternal>
      ) : (
        <ListButton to={link} variant={variant}>
          {children}
        </ListButton>
      )}
    </>
  );
};

export default ButtonLink;
